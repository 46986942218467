import { useEffect, useRef, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table';

import {
  THead,
  TR as SdsTr,
  TH as SdsTh,
  TBody,
  TD as SdsTd,
  Flexbox,
  SorterButton,
  IPaginationProps,
  EmptyState,
  PaginationSelect,
  TextInput,
  Icons,
  Sizes
} from '@sede-x/shell-ds-react-framework';

import { PAGE_SIZE_OPTIONS } from 'constants/table';
import { CustomTable } from './types';
import {
  SDSTable,
  StyledTableWrapper,
  TableContainer,
  StyledPagination
} from './style';
import ExportTableData from './TableExport';
import ShowHideColumns from './ShowHideColumns';
import TableTooltip from './TableTooltip';

const DEFAULT_MAX_HEIGHT = 0;
const DEFAULT_HEADER_SIZE = 150;

function Table<TData>({
  data,
  columns,
  exportFileName = 'report',
  stickyColumns,
  maxHeight = DEFAULT_MAX_HEIGHT,
  maxWidth,
  stickyHeader = true,
  paginationData,
  renderRowSubComponent,
  handleClickExportAll,
  exportAllEnabled,
  exportEnabled = true,
  columnSelection = true,
  onSelectedRowsChange,
  enableMultiRowSelection = true,
  resetSelectedRowsOnPageChange = true,
  onRowDoubleClick,
  onRowClick,
  globalFilterEnabled = false,
  enableRowSelection,
  getRowId,
  selectedRows,
  getTooltip,
  rowSelectionState,
  setRowSelectionState,
  meta
}: Readonly<CustomTable<TData>>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    if (selectedRows) {
      setRowSelection(selectedRows);
    }
  }, [selectedRows]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection: rowSelectionState || rowSelection,
      globalFilter
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelectionState || setRowSelection,
    enableRowSelection,
    manualPagination: true,
    enableMultiRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    enableGlobalFilter: true,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'includesString',
    getRowId,
    meta
  });

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (onSelectedRowsChange) {
      onSelectedRowsChange(
        table.getSelectedRowModel().flatRows.map((row) => row.original),
        rowSelection
      );
    }
  }, [rowSelection, table]);

  const wrapperProps = {
    ...(maxHeight && { maxHeight }),
    ...(maxWidth && { maxWidth }),
    ...(stickyHeader && {
      stickyRows: stickyHeader
    })
  };

  const handlePageChange = (pageNumber: number, newPageSize: number) => {
    if (resetSelectedRowsOnPageChange) {
      setRowSelection({});
    }
    paginationData?.onPaginationChange(pageNumber, newPageSize);
  };

  const paginationProps: IPaginationProps = {
    total: paginationData?.total,
    current: paginationData?.page,
    pageSize: paginationData?.pageSize,
    onChange: handlePageChange,
    showSizeChanger: true,
    selectComponentClass: PaginationSelect,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTotal: (total) => `Total ${total} items`
  };

  const isEmpty = table.getRowModel().rows.length === 0;
  const title = 'No data found';

  return (
    <>
      <TableContainer className="pt-4 gap-4">
        {globalFilterEnabled && (
          <div>
            <div className=" md:w-[30%] lg:w-[30%]">
              <TextInput
                suffix={{
                  node: <Icons.Search height={24} width={24} />
                }}
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                data-testid="search-input"
                clearable
                onClear={() => {
                  setGlobalFilter('');
                }}
              />
            </div>
          </div>
        )}
        {columnSelection && <ShowHideColumns<TData> table={table} />}
        <StyledTableWrapper {...wrapperProps}>
          <div>
            <SDSTable size={Sizes.Small}>
              <THead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <SdsTr
                    size={Sizes.Small}
                    key={headerGroup.id}
                    depth={headerGroup.depth}
                    {...(stickyHeader && {
                      sticky: true,
                      stickyType: 'header'
                    })}
                    style={{
                      zIndex: 2
                    }}
                  >
                    {headerGroup.headers.map((header) => (
                      <SdsTh
                        key={header.id}
                        colSpan={header.colSpan}
                        {...(stickyColumns?.includes(header?.column.id) && {
                          sticky: true,
                          stickyType: 'column',
                          startingPoint: header?.column.getStart()
                        })}
                        style={{
                          width:
                            header.getSize() !== DEFAULT_HEADER_SIZE
                              ? header.getSize()
                              : undefined
                        }}
                        size={Sizes.Small}
                      >
                        <Flexbox
                          gap="12px"
                          alignItems="center"
                          className="whitespace-nowrap"
                        >
                          <>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <SorterButton
                                sortDirection={
                                  header.column.getIsSorted() || undefined
                                }
                                onClick={header.column.getToggleSortingHandler()}
                                data-testid={`sort-btn-${header.column.id}`}
                              />
                            )}
                          </>
                        </Flexbox>
                      </SdsTh>
                    ))}
                  </SdsTr>
                ))}
              </THead>
              <TBody>
                {isEmpty && (
                  <SdsTr>
                    <SdsTd
                      colSpan={table.getAllColumns().length}
                      style={{ padding: 'unset' }}
                    >
                      <EmptyState
                        title={title}
                        style={{ padding: '60px', backgroundColor: 'white' }}
                      />
                    </SdsTd>
                  </SdsTr>
                )}
                {table.getRowModel().rows.map((row) => (
                  <>
                    <TableTooltip message={getTooltip?.(row)}>
                      <SdsTr
                        key={row.id}
                        onDoubleClick={() => onRowDoubleClick?.(row)}
                        onClick={() => onRowClick?.(row)}
                        className={`${
                          onRowDoubleClick || onRowClick ? 'cursor-pointer' : ''
                        }`}
                        style={
                          table.options.meta?.getRowStyles
                            ? table.options.meta.getRowStyles(row)
                            : undefined
                        }
                        size={Sizes.Small}
                        {...(table.options.meta?.getRowProps
                          ? table.options.meta.getRowProps(row)
                          : undefined)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <SdsTd
                            key={cell.id}
                            {...(stickyColumns?.includes(cell?.column.id) && {
                              sticky: true,
                              stickyType: 'column',
                              startingPoint: cell?.column.getStart()
                            })}
                            className="whitespace-nowrap"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </SdsTd>
                        ))}
                      </SdsTr>
                    </TableTooltip>
                    {row.getIsExpanded() ? (
                      <SdsTr key={`exp_${row.id}`}>
                        <SdsTd
                          colSpan={table.getVisibleFlatColumns().length}
                          style={{ padding: 'unset' }}
                        >
                          {renderRowSubComponent
                            ? renderRowSubComponent(row)
                            : null}
                        </SdsTd>
                      </SdsTr>
                    ) : null}
                  </>
                ))}
              </TBody>
            </SDSTable>
          </div>
        </StyledTableWrapper>
      </TableContainer>
      <div className="flex flex-col gap-5">
        {paginationData && (
          <StyledPagination
            {...paginationProps}
            size={Sizes.Small}
            position="center"
          />
        )}
        {exportEnabled && (
          <ExportTableData<TData>
            fileName={exportFileName}
            table={table}
            handleClickExportAll={handleClickExportAll}
            exportAllEnabled={exportAllEnabled}
          />
        )}
      </div>
    </>
  );
}

export default Table;
