import {
  Alignments,
  Modal,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import DeliveryAddress from './components/DeliveryAddress';
import OBUProduct from './components/OBUProduct';
import DeliveryProduct from './components/DeliveryProduct';
import VehicleList from './components/VehicleList';
import { SaveOBUOrderDto, VehicleRef } from './components/types';
import Summary from './components/Summary';

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;
interface OrderAndPersonalizeObuProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 5;
const STEP_3 = 3;
const STEP_5 = 5;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'OBU Product';
    case 2:
      return 'Delivery Address';
    case STEP_3:
      return 'Delivery Product';
    case 4:
      return 'Vehicle';
    case STEP_5:
      return 'Summary';
    default:
      return '';
  }
};

const OrderAndPersonalizeObuActions: React.FC<OrderAndPersonalizeObuProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const vehicleRef = useRef<VehicleRef>(null);

  const formRef = useRef<FormikProps<SaveOBUOrderDto>>(null);
  const [step, setStep] = useState<number>(1);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (step === 4 && !isBackClick && !vehicleRef.current?.validateVehicle()) {
      return;
    }
    setStep(num);
  };

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size="small">T4E OBU Order and Personalize</Text>
        <Text prominence="strong" bold size="medium">
          {' '}
          {getTitles(step)}
        </Text>
      </div>
      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return !values.productTypeID;
      case 2:
        return !values.countryTypeID;
      case STEP_3:
        return !values.deliveryProductTypeID;
      case 4:
        return !values.vehicleIds;
      default:
        return false;
    }
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('productTypeID', undefined);
        formRef.current?.setFieldValue('amount', undefined);
        formRef.current?.setFieldValue('obuProduct', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('contactID', undefined);
        formRef.current?.setFieldValue('countryTypeID', undefined);
        formRef.current?.setFieldValue('contactPersonFullName', undefined);
        formRef.current?.setFieldValue('contactPersonAddress', undefined);
        break;
      case 4:
        formRef.current?.setFieldValue('deliveryProductTypeID', undefined);
        formRef.current?.setFieldValue('deliveryProduct', undefined);
        break;
      case STEP_5:
        formRef.current?.setFieldValue('vehicleIds', undefined);
        break;
      default:
        break;
    }
  };

  const handleSave = (
    values: SaveOBUOrderDto,
    formikHelpers: FormikHelpers<SaveOBUOrderDto>
  ) => {
    const { setSubmitting } = formikHelpers;

    const payload = { ...values };
    delete payload.contactDetails;

    const endpoint = customerEndPoints.saveOBUOrderAndPersonalize;

    customerInstance
      .post(endpoint, { ...payload })
      .then(() => {
        successDialog('', 'Request has been successfully saved');
        setStep(1);
        handleOnCloseModal();
      })
      .catch((error) => {
        const message = 'Your request has been failed.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{} as SaveOBUOrderDto}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="60%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <OBUProduct
              customerId={CustomerId}
              searchType="orderAndPersonalizeObu"
            />
          )}
          {step === 2 && <DeliveryAddress customerId={CustomerId} />}
          {step === STEP_3 && (
            <DeliveryProduct countryTypeID={values.countryTypeID} />
          )}
          {step === 4 && (
            <VehicleList customerId={CustomerId} ref={vehicleRef} />
          )}
          {step === STEP_5 && <Summary searchType="orderAndPersonalizeObu" />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default OrderAndPersonalizeObuActions;
