import {
  TrailerTypeEnum,
  VehicleCategoryTypeEnum
} from 'utils/constants/constants';
import { CountryTypeMap } from 'utils/Interfaces/StaticData';
import { ErrorMessagesText } from './ErrorMessagesText';

interface VehicleModel {
  vehicleId: string;
  registrationNumber: string;
  country: string;
  category: string;
  emissionClass: string;
  axles: number;
  truckTractorAxlesNumber: number;
  trailerAxlesNumber: number;
  grossVehicleWeightRating: number;
  authorizedGrossVehicleWeight: number;
  vin: string | null;
  totalVehicleWeight: number;
  particleReductionClass: string | null;
  vehicleModelTypeId: string | null;
  manufacturer: string;
  firstDayOfRegistration: string;
  trailerMaxLadenWeight: number | null;
  trailerTypeId: string | null;
  weightF1: number | null;
  isEnabled: number;
}

interface VehicleSTVService {
  cardNumber: string;
}

const MAX_VEHICLE_WEIGHT = 65535.0;
const MAX_VIN_LENGTH = 17;

export class VehicleValidator {
  static readonly WeightLimit: number = 3500;

  public static ValidateSelection(
    models: VehicleModel[],
    vehicleSTVService?: VehicleSTVService,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ): string[] {
    for (const vehicleModel of models) {
      const result = this.ValidateVehicle(
        vehicleModel,
        vehicleSTVService,
        pendingVehicles,
        vehiclesWithObu
      );
      if (result.length) {
        return result;
      }
    }

    return [];
  }

  public static ValidateForFormattingWithPendingVehicle(
    vehicleModel: VehicleModel,
    pendingVehicles: string[]
  ): string {
    let message = '';
    if (pendingVehicles.includes(vehicleModel.vehicleId)) {
      message = `${ErrorMessagesText.VehicleValidator_PendingVehicle}, RequestVehicle`;
    }

    return message;
  }

  public static ValidateForFormattingWithPendingVehicleAndObus(
    vehicleModel: VehicleModel,
    pendingVehicles: string[],
    vehiclesWithObus: string[]
  ): string[] {
    const messages = [];
    const result = VehicleValidator.ValidateForFormattingWithPendingVehicle(
      vehicleModel,
      pendingVehicles
    );
    if (result) {
      messages.push(result);
    }
    if (vehiclesWithObus.includes(vehicleModel.vehicleId)) {
      messages.push(ErrorMessagesText.VehicleValidator_ObuAlreadyAssigned);
    }

    return messages;
  }

  public static ValidateVehicle(
    vehicleModel: VehicleModel,
    vehicleSTVService?: VehicleSTVService,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ) {
    const message = [];
    if (!vehicleModel.registrationNumber) {
      message.push(
        ErrorMessagesText.VehicleValidator_RegistrationNumber(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (!vehicleModel.country) {
      message.push(
        ErrorMessagesText.VehicleValidator_Country(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (!vehicleModel.emissionClass) {
      message.push(
        ErrorMessagesText.VehicleValidator_EmissionClass(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (vehicleModel.axles <= 0) {
      message.push(
        ErrorMessagesText.VehicleValidator_Axles(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (
      !vehicleModel.grossVehicleWeightRating ||
      !(
        vehicleModel.grossVehicleWeightRating >= 1.0 &&
        vehicleModel.grossVehicleWeightRating <= MAX_VEHICLE_WEIGHT
      )
    ) {
      message.push(ErrorMessagesText.VehicleValidator_GrossWeight);
      return message;
    }

    const weightMessage = this.CheckVehicleGrossWeight(vehicleModel);
    if (weightMessage) {
      message.push(weightMessage);
      return message;
    }

    if (!vehicleModel.category) {
      message.push(
        ErrorMessagesText.VehicleValidator_Category(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    // ------------ according to BUG  VSO 2089 -----------------//

    if (!vehicleModel.vin) {
      message.push(
        ErrorMessagesText.VehicleValidator_VIN(vehicleModel.registrationNumber)
      );
      return message;
    }

    // acc. to ISO norm a VIN number is 17 characters long

    if (vehicleModel.vin.length !== MAX_VIN_LENGTH) {
      message.push(
        ErrorMessagesText.VehicleValidator_VIN_Format(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    message.push(...this.CheckVehicleSTVCardNumber(vehicleSTVService));

    message.push(
      ...this.ValidateVehicleWithPendingVehicleOBU(
        vehicleModel,
        pendingVehicles,
        vehiclesWithObu
      )
    );

    return message;
  }

  public static CheckVehicleSTVCardNumber(
    vehicleSTVService?: VehicleSTVService
  ): string[] {
    const message = [];
    if (vehicleSTVService) {
      const result = this.ValidateSTVCardNumber(vehicleSTVService);
      if (result) {
        message.push(ErrorMessagesText.VehicleValidator_STVCardNumberMissing);
      }
    }
    return message;
  }

  public static CheckVehicleGrossWeight(vehicleModel: VehicleModel): string {
    let message = '';
    if (
      !vehicleModel.authorizedGrossVehicleWeight ||
      !(
        vehicleModel.authorizedGrossVehicleWeight >= 1.0 &&
        vehicleModel.authorizedGrossVehicleWeight <= MAX_VEHICLE_WEIGHT
      )
    ) {
      message = ErrorMessagesText.VehicleValidator_GrossWeight;
    }

    return message;
  }

  public static ValidateVehicleWithPendingVehicleOBU(
    vehicleModel: VehicleModel,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ): string[] {
    const message = [];
    if (pendingVehicles?.length && vehiclesWithObu?.length) {
      message.push(
        ...this.ValidateForFormattingWithPendingVehicleAndObus(
          vehicleModel,
          pendingVehicles,
          vehiclesWithObu
        )
      );
    } else if (pendingVehicles?.length) {
      message.push(
        this.ValidateForFormattingWithPendingVehicle(
          vehicleModel,
          pendingVehicles
        )
      );
    }

    return message;
  }

  public static ValidateSTVCardNumber(
    vehicleSTVService: VehicleSTVService
  ): string {
    const cardNumber = vehicleSTVService?.cardNumber;
    let message = '';

    if (!cardNumber) {
      message = ErrorMessagesText.VehicleValidator_STVCardNumberMissing;
    }

    return message;
  }

  public static ValidateForOpenedRequests(
    model: VehicleModel,
    openedVehicles: string[]
  ): string {
    let message = '';
    if (openedVehicles?.includes(model.vehicleId)) {
      message = ErrorMessagesText.VehicleValidator_PendingVehicle;
    }
    return message;
  }

  public static ValidateForHungary(models: VehicleModel[]): string[] {
    const messages = [];
    for (const vehicleModel of models) {
      if (!vehicleModel.vehicleModelTypeId) {
        messages.push(
          `Vehicle ${vehicleModel.registrationNumber} doesn't have specified manufacturer!`
        );
      }

      if (!vehicleModel.firstDayOfRegistration) {
        messages.push(
          `Vehicle ${vehicleModel.registrationNumber} doesn't have specified year of construction!`
        );
      }
    }

    return messages;
  }

  public static ValidateForSwitzerland(
    models: VehicleModel[],
    CountryTypeEnum?: CountryTypeMap
  ): string[] {
    const errorMessages = [];
    for (const v of models) {
      errorMessages.push(...this.ValidateTrailerType(v));
      errorMessages.push(...this.ValidateTrailerWeight(v));
      errorMessages.push(...this.ValidateCountry(v, CountryTypeEnum));
      errorMessages.push(...this.ValidateVehicleCategoryForSwitzerland(v));
    }
    return errorMessages;
  }

  private static ValidateTrailerType(vehicle: VehicleModel): string[] {
    const errorMessages = [];
    if (!vehicle.trailerTypeId) {
      errorMessages.push(
        `Vehicle '${vehicle.registrationNumber}' doesn't have set trailer type.`
      );
    }
    return errorMessages;
  }

  private static ValidateTrailerWeight(vehicle: VehicleModel): string[] {
    const errorMessages = [];
    if (
      !vehicle.trailerTypeId &&
      (!vehicle.trailerMaxLadenWeight || vehicle.trailerMaxLadenWeight > 1)
    ) {
      errorMessages.push(
        `Vehicle '${vehicle.registrationNumber}' doesn't have set trailer maximum laden weight or weight value is lower than 1.`
      );
    }

    if (
      vehicle.trailerTypeId &&
      vehicle.trailerTypeId.toLocaleLowerCase() !==
        TrailerTypeEnum.No_trailer.key &&
      (!vehicle.trailerMaxLadenWeight || vehicle.trailerMaxLadenWeight < 1)
    ) {
      errorMessages.push(
        `Vehicle '${vehicle.registrationNumber}' doesn't have set trailer maximum laden weight or weight value is lower than 1.`
      );
    }

    if (
      vehicle.trailerTypeId &&
      vehicle.trailerTypeId.toLocaleLowerCase() ===
        TrailerTypeEnum.No_trailer.key &&
      vehicle.trailerMaxLadenWeight &&
      vehicle.trailerMaxLadenWeight > 1
    ) {
      errorMessages.push(
        `Vehicle '${vehicle.registrationNumber}' has No trailer and maximum laden weight or weight value is higher than 1.`
      );
    }
    return errorMessages;
  }

  private static ValidateCountry(
    vehicle: VehicleModel,
    CountryTypeEnum?: CountryTypeMap
  ): string[] {
    const errorMessages = [];
    if (!CountryTypeEnum) {
      errorMessages.push('Not able to validate country');
      return errorMessages;
    }
    if (
      vehicle.country === CountryTypeEnum.Switzerland.name ||
      vehicle.country === CountryTypeEnum.Liechtenstein.name
    ) {
      errorMessages.push(
        `Vehicle '${vehicle.registrationNumber}' is from ${vehicle.country} and thus cannot have activated Switzerland product.`
      );
    }
    return errorMessages;
  }

  private static ValidateVehicleCategoryForSwitzerland(
    vehicle: VehicleModel
  ): string[] {
    const errorMessages = [];
    if (
      vehicle.category === VehicleCategoryTypeEnum.M1.name ||
      vehicle.category === VehicleCategoryTypeEnum.M2.name ||
      vehicle.category === VehicleCategoryTypeEnum.M3.name
    ) {
      errorMessages.push(
        `Vehicle ${vehicle.registrationNumber} is of category ${vehicle.category} and Buses/Coaches are exempt from toll payment in Switzerland.`
      );
    }
    return errorMessages;
  }

  public static ValidateForAustria(models: VehicleModel[]): string[] {
    const errorMessages = [];
    for (const vehicleModel of models) {
      if (vehicleModel.weightF1 && vehicleModel.weightF1 <= this.WeightLimit) {
        errorMessages.push(
          `Vehicle ${vehicleModel.registrationNumber} cannot be registered in Austria - vehicle weight needs to be higher than 3.5t!`
        );
      }
    }

    return errorMessages;
  }

  public static ValidateForBelgium(models: VehicleModel[]): string[] {
    return this.ValidateForVehicleCategory(models, 'Belgium');
  }

  public static ValidateForGermany(models: VehicleModel[]): string[] {
    return this.ValidateForVehicleCategory(models, 'Germany');
  }

  public static ValidateForVehicleCategory(
    models: VehicleModel[],
    country: string
  ): string[] {
    const errorMessages = [];
    for (const vehicleModel of models) {
      if (
        vehicleModel.category === VehicleCategoryTypeEnum.M1.name ||
        vehicleModel.category === VehicleCategoryTypeEnum.M2.name ||
        vehicleModel.category === VehicleCategoryTypeEnum.M3.name
      ) {
        errorMessages.push(
          `Vehicle '${vehicleModel.registrationNumber}' is of category ${vehicleModel.category} and Buses/Coaches are exempt from toll payment in ${country}.`
        );
      }
    }

    return errorMessages;
  }
}
