import {
  Alignments,
  Modal,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import DeliveryAddress from './components/DeliveryAddress';
import DeliveryProduct from './components/DeliveryProduct';
import OBUProduct from './components/OBUProduct';
import Summary from './components/Summary';
import { SaveOBUOrderDto } from './components/types';

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface OrderAndNoPersonalizeObuProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 4;
const STEP_3 = 3;
const firstNameError = 'There is no First name defined for selected contact';
const lastNameError = 'There is no Last name defined for selected contact';
const phoneError = 'There is no Phone defined for selected contact';
const emailError = 'There is no Mail defined for selected contact';

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'OBU Product';
    case 2:
      return 'Delivery Address';
    case STEP_3:
      return 'Delivery Product';
    case 4:
      return 'Summary';
    default:
      return '';
  }
};

const OrderAndNoPersonalizeObuActions: React.FC<
  OrderAndNoPersonalizeObuProps
> = ({ CustomerId, customerName, handleOnCloseModal }) => {
  const formRef = useRef<FormikProps<SaveOBUOrderDto>>(null);
  const [step, setStep] = useState<number>(1);

  const { errorDialog, successDialog, infoDialog } = useConfirmDialogs();

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('productTypeID', undefined);
        formRef.current?.setFieldValue('amount', undefined);
        formRef.current?.setFieldValue('obuProduct', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('contactID', undefined);
        formRef.current?.setFieldValue('countryTypeID', undefined);
        formRef.current?.setFieldValue('contactPersonFullName', undefined);
        formRef.current?.setFieldValue('contactPersonAddress', undefined);
        break;
      case 4:
        formRef.current?.setFieldValue('deliveryProductTypeID', undefined);
        formRef.current?.setFieldValue('deliveryProduct', undefined);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const validateContactDetails = () => {
    const { firstName, lastName, phone, mail } =
      formRef.current?.values?.contactDetails ?? {};
    const errors = [];

    if (!firstName) {
      errors.push(firstNameError);
    }
    if (!lastName) {
      errors.push(lastNameError);
    }
    if (!phone) {
      errors.push(phoneError);
    }
    if (!mail) {
      errors.push(emailError);
    }

    return errors.join('<br />');
  };

  const handleOnStepClick = async (num: number, isBackClick = false) => {
    const errorMsg = validateContactDetails();
    const title = 'Error';

    if (step === 2 && !isBackClick && errorMsg) {
      const isConfirmed = await infoDialog(title, errorMsg);
      if (!isConfirmed) {
        setStep(2);
        return;
      }
    }

    setStep(num);
  };

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size="small">T4E OBU Order</Text>
        <Text prominence="strong" bold size="medium">
          {getTitles(step)}
        </Text>
      </div>
      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return (
          !values.productTypeID ||
          !values.amount ||
          Number(values.amount) > 100 ||
          Number(values.amount) < 1
        );
      case 2:
        return !values.countryTypeID;
      case STEP_3:
        return !values.deliveryProductTypeID;
      default:
        return false;
    }
  };

  const handleSave = (
    values: SaveOBUOrderDto,
    formikHelpers: FormikHelpers<SaveOBUOrderDto>
  ) => {
    const { setSubmitting } = formikHelpers;

    const payload = { ...values };
    delete payload.deliveryProduct;
    delete payload.contactPersonAddress;
    delete payload.obuProduct;
    delete payload.contactPersonFullName;
    delete payload.deliveryProduct;
    delete payload.countryTypeID;
    const endpoint = customerEndPoints.saveOBUOrderAndNoPersonalize;

    customerInstance
      .post(endpoint, {
        ...payload
      })
      .then(() => {
        successDialog('', 'Request has been successfully saved');
        setStep(1);
        handleOnCloseModal();
      })
      .catch((error) => {
        const message = 'Your request has been failed.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{} as SaveOBUOrderDto}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
      validate={(values) => {
        const errors: Partial<SaveOBUOrderDto> = {};
        if (!values.amount) {
          errors.amount = 'Please select OBU Number';
        }
        if (Number(values.amount) > 100 || Number(values.amount) < 1) {
          errors.amount = 'Number of OBUs per order must be between 1 and 100';
        }
        return errors;
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="60%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <OBUProduct customerId={CustomerId} searchType="orderObu" />
          )}
          {step === 2 && <DeliveryAddress customerId={CustomerId} />}
          {step === STEP_3 && (
            <DeliveryProduct countryTypeID={values.countryTypeID} />
          )}
          {step === 4 && <Summary searchType="orderObu" />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default OrderAndNoPersonalizeObuActions;
