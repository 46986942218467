import { t4eEndpoints } from 'api/apiEndpoints';
import LoadingModal from 'components/LoadingModal/LoadingModal';
import useValidateT4E from 'hooks/useValidateT4E';
import React from 'react';
import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import { ErrorModal } from '../../../styles';

interface T4EValidationProps {
  CustomerId: string;
  handleOnCloseModal: () => void;
  children: React.ReactNode;
}

const T4EValidation: React.FC<T4EValidationProps> = ({
  CustomerId,
  handleOnCloseModal: handleClose,

  children
}) => {
  const { message, error, success, loading } = useValidateT4E(
    CustomerId,
    true,
    t4eEndpoints.validateCustomerUpdate
  );

  if (loading) {
    return (
      <LoadingModal
        modalProps={{
          mask: false
        }}
      />
    );
  }
  if (error) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="t4e-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">
            Failed to validate the customer
          </div>
        </Alert>
      </ErrorModal>
    );
  }

  if (!success) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="action-validation-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">{message}</div>
        </Alert>
      </ErrorModal>
    );
  }

  return <>{children}</>;
};

export default T4EValidation;
