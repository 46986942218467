import React, { ReactNode } from 'react';
import { BaseTable, Card, Sizes, Text } from '@sede-x/shell-ds-react-framework';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { FormikValues, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { VehicleProductsDto } from './types';

const datePattern = 'DD/MM/YYYY hh:mm A';
const rentalFeePattern = 'DD/MM/YYYY';

const BoldText = ({ children }: { children: ReactNode }) => (
  <span className="font-bold">{children}</span>
);

const rentalFeeColumns = [
  { header: 'From', accessorKey: 'startDate' },
  { header: 'To', accessorKey: 'endDate' },
  { header: 'Value', accessorKey: 'fee' }
];

const vehicleColumns = [
  ...OverviewColumns.VehicleList.map((column) => ({
    header: column.label,
    accessorKey: column.field
  })),
  {
    header: 'Particle Reduction Class',
    accessorKey: 'particleReductionClass'
  }
];
interface SummaryProps {
  title?: string;
}

const Summary: React.FC<SummaryProps> = ({
  title = 'Customer contract registration settings'
}) => {
  const { values } = useFormikContext<FormikValues>();

  const { address, card, rentalFee, rentalFeeNP, notUsedThreePlusMonthsFee } =
    values || {};
  const { cardNo, validTo } = card || {};
  const { defalutValue } = rentalFee || {};
  const { defalutValue: defalutValueNP } = rentalFeeNP || {};

  return (
    <div className="flex flex-col grow overflow-y-auto p-5 bg-shellExtraPaleGrey2">
      <div className="flex">
        <Text>
          <BoldText>{title}</BoldText>
        </Text>
      </div>
      <div className=" flex flex-col gap-4 p-6">
        {address && (
          <Card
            header={{ title: 'Selected Address' }}
            size={Sizes.Small}
            bodyBgColor
          >
            {address && <Text size={Sizes.Small}>{address?.fullAddress}</Text>}
          </Card>
        )}
        {card && (
          <Card
            header={{ title: 'Selected Card' }}
            size={Sizes.Small}
            bodyBgColor
          >
            {cardNo && (
              <Text size={Sizes.Small}>{`
            ${cardNo}, 
           ${dayjs(validTo).format(datePattern)}
          `}</Text>
            )}
          </Card>
        )}
        {rentalFee && (
          <Card header={{ title: 'Rental Fee' }} size={Sizes.Small} bodyBgColor>
            <div className="flex flex-col gap-4">
              {defalutValue && (
                <Text size={Sizes.Small}>{`
            Default Fee: ${defalutValue}`}</Text>
              )}
              {rentalFee.startDate && rentalFee.endDate && rentalFee.fee && (
                <div>
                  <BaseTable
                    columns={rentalFeeColumns}
                    data={[rentalFee].map((item) => ({
                      startDate: dayjs(item.startDate).format(rentalFeePattern),
                      endDate: dayjs(item.endDate).format(rentalFeePattern),
                      fee: item.fee
                    }))}
                  />
                </div>
              )}
            </div>
          </Card>
        )}
        {rentalFeeNP && (
          <Card
            header={{ title: 'Rental Fee for Never Personalized OBUs' }}
            size={Sizes.Small}
            bodyBgColor
          >
            <div className="flex flex-col gap-4">
              {defalutValueNP && (
                <Text size={Sizes.Small}>{`
            Default Fee: ${defalutValueNP}`}</Text>
              )}
              {rentalFeeNP.startDate &&
                rentalFeeNP.endDate &&
                rentalFeeNP.fee && (
                  <div>
                    <BaseTable
                      columns={rentalFeeColumns}
                      data={[rentalFeeNP].map((item) => ({
                        startDate: dayjs(item.startDate).format(
                          rentalFeePattern
                        ),
                        endDate: dayjs(item.toDate).format(rentalFeePattern),
                        fee: item.fee
                      }))}
                    />
                  </div>
                )}
            </div>
          </Card>
        )}
        {notUsedThreePlusMonthsFee && (
          <Card>
            <Text size={Sizes.Medium}>
              Not used 3+ months fee: {notUsedThreePlusMonthsFee}
            </Text>
          </Card>
        )}

        <RenderVehicleAndProduct />
      </div>
    </div>
  );
};

const RenderVehicleAndProduct = () => {
  const { values } = useFormikContext<FormikValues>();

  const {
    vehicles,
    vehecleProducts,
    discountNames,
    obuProduct,
    contactDetails,
    deliveryProduct
  } = values || {};

  const { street, streetNumber, city, zip, country } = contactDetails || {};

  return (
    <>
      {vehicles && (
        <Card
          header={{ title: 'Selected Vehicles' }}
          size={Sizes.Small}
          bodyBgColor
        >
          <BaseTable columns={vehicleColumns} data={vehicles} />
        </Card>
      )}

      {obuProduct && contactDetails && deliveryProduct && (
        <Card
          header={{ title: 'OBU order and personalization settings' }}
          size={Sizes.Small}
          bodyBgColor
        >
          <div className="grid grid-cols-2 gap-4">
            <Text size={Sizes.Small}>Product:</Text>
            <Text size={Sizes.Small}>{obuProduct}</Text>
            <Text size={Sizes.Small}>Delivery Address:</Text>
            <Text size={Sizes.Small}>{`${street ?? ''} ${streetNumber ?? ''}, ${
              city ?? ''
            }, ${zip ?? ''}, ${country ?? ''}`}</Text>
            <Text size={Sizes.Small}>Delivery Product:</Text>
            <Text size={Sizes.Small}>{`${deliveryProduct}`}</Text>
          </div>
        </Card>
      )}
      {vehecleProducts && (
        <Card
          header={{ title: 'Selected services' }}
          size={Sizes.Small}
          bodyBgColor
        >
          {vehecleProducts.map((item: VehicleProductsDto) => (
            <Text key={item.value} size={Sizes.Small}>
              {item.name}
            </Text>
          ))}
        </Card>
      )}

      {discountNames && (
        <Card header={{ title: 'Discounts' }} size={Sizes.Small} bodyBgColor>
          {discountNames.map((item: string) => (
            <Text key={item} size={Sizes.Small}>
              {item}
            </Text>
          ))}
        </Card>
      )}
    </>
  );
};
export default Summary;
