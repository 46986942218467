import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { Field, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import SelectField from 'components/FormCmponents/SelectField';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import depersonalizeReasons from 'utils/StaticData/Obu/depersonalizeReasons.json';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { columnBuilder } from 'utils/helpers';
import { SaveDepersonalizeObuDto, ProductDetailDto } from './types';

const DEFAULT_PAGE_SIZE = 20;

async function fetchObuProducts(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.getDepersonalizeOBUProduct, {
    customerId,
    pageNumber,
    pageSize
  });
}
interface OBUProductProps {
  customerId?: string;
}

const columnHelper = createColumnHelper<ProductDetailDto>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!row.original.isEnabled && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];

const OBUProduct: React.FC<OBUProductProps> = ({ customerId }) => {
  const { setFieldValue } = useFormikContext<SaveDepersonalizeObuDto>();
  const [selectedRows, setSelectedRows] = useState<ProductDetailDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['get-obu-model', customerId, pageSize, pageNumber],
    queryFn: () =>
      fetchObuProducts(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    refetchOnWindowFocus: false
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('customerId', customerId);
      setFieldValue('obuSerialNumber', row.obuSerialNumber);
      setFieldValue('obuId', row.id);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<ProductDetailDto>
          data={data?.data ?? []}
          columns={
            [
              ...actions,
              ...columnBuilder(OverviewColumns.OBUProductDepersonalize)
            ] as ColumnDef<ProductDetailDto>[]
          }
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          enableRowSelection={(row) => !!row.original.isEnabled}
          meta={{
            getRowStyles: (row) => ({
              backgroundColor: row.original.isEnabled ? '' : '#DFDFDF'
            })
          }}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          getTooltip={(row) => row.original.validationMessage}
        />
        <div className="w-1/3">
          <Field
            id="reason"
            name="reason"
            label="Reasons"
            labelPosition="left"
            placeholder="Select"
            component={SelectField}
            options={depersonalizeReasons.map((item) => ({
              label: item.Name,
              value: item.Value
            }))}
            onChange={(value: string) => {
              setFieldValue('reason', value);
              const selectedReason = depersonalizeReasons.find(
                (reason) => reason.Value === Number(value)
              )?.Name;
              setFieldValue('selectedReason', selectedReason);
            }}
          />
        </div>
      </QueryError>
    </div>
  );
};

export default OBUProduct;
