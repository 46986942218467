import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { FormikValues, useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { columnBuilder } from 'utils/helpers';
import { VehicleListDto, VehicleRef } from './types';
import {
  constructErrors,
  fetchCustomerOpenedRequestsVehicle,
  fetchCustomerPendingVehicles,
  fetchCustomerVehiclesWithObu
} from '../../../utils/helper';
import { VehicleValidator } from '../../../utils/VehicleValidator';

const DEFAULT_PAGE_SIZE = 20;
interface VehicleListProps {
  customerId: string;
}

async function fetchVehicleList(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(
    'obu/actions/t4e/get-obu-complete-transfer-vehicles',
    {
      customerId,
      pageNumber,
      pageSize
    }
  );
}

const columnHelper = createColumnHelper<VehicleListDto>();
const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        {!!row.original.isEnabled && (
          <RadioButton
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`rowcheck-${row.index}`}
            size={Sizes.ExtraSmall}
          />
        )}
      </Flexbox>
    )
  })
];
const VehicleList = forwardRef<VehicleRef, VehicleListProps>(
  ({ customerId }, ref) => {
    const { setFieldValue } = useFormikContext<FormikValues>();
    const [selectedRows, setSelectedRows] = useState<VehicleListDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: [
        'obu-complete-transfer-vehicles',
        customerId,
        pageSize,
        pageNumber
      ],
      queryFn: () =>
        fetchVehicleList(customerId, pageNumber, pageSize).then(
          (res) => res.data
        ),
      refetchOnWindowFocus: false
    });

    const { data: pendingVehiclesData } = useQuery({
      queryKey: ['get-pending-vehicles', customerId],
      queryFn: () =>
        fetchCustomerPendingVehicles(customerId).then((res) => res.data),
      refetchOnWindowFocus: 'always',
      retry: false
    });

    const { data: vehiclesWithObuData } = useQuery({
      queryKey: ['get-vehicles-with-obu', customerId],
      queryFn: () =>
        fetchCustomerVehiclesWithObu(customerId).then((res) => res.data),
      refetchOnWindowFocus: 'always',
      retry: false
    });

    const { data: openedRequestsVehicleData } = useQuery({
      queryKey: ['get-opened-requests-vehicle', customerId],
      queryFn: () =>
        fetchCustomerOpenedRequestsVehicle(customerId).then((res) => res.data),
      refetchOnWindowFocus: 'always',
      retry: false
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        const row = selectedRows[0];
        setFieldValue('vehicleId', row.vehicleId);
        setFieldValue('registrationNumber', row.registrationNumber);
        setFieldValue('country', row.country);
      } else {
        setFieldValue('vehicleId', undefined);
        setFieldValue('registrationNumber', undefined);
        setFieldValue('country', undefined);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      validateVehicle() {
        const VALIDATION_TITLE = 'Validation Error';
        if (isError) {
          return false;
        }

        const selectedVehicle = selectedRows[0];
        const pendingVehicles = pendingVehiclesData?.data;
        const vehiclesWithObu = vehiclesWithObuData?.data;
        const openedRequestsVehicles = openedRequestsVehicleData?.data;

        if (selectedVehicle && pendingVehicles && vehiclesWithObu) {
          let error =
            VehicleValidator.ValidateForFormattingWithPendingVehicleAndObus(
              selectedVehicle,
              pendingVehicles,
              vehiclesWithObu
            );

          if (error.length) {
            infoDialog(VALIDATION_TITLE, constructErrors(error));
            return false;
          }

          error = VehicleValidator.ValidateVehicle(selectedVehicle);
          if (error.length) {
            infoDialog(VALIDATION_TITLE, constructErrors(error));
            return false;
          }

          const err = VehicleValidator.ValidateForOpenedRequests(
            selectedVehicle,
            openedRequestsVehicles
          );

          if (err) {
            infoDialog(VALIDATION_TITLE, err);
            return false;
          }
        } else {
          infoDialog(VALIDATION_TITLE, 'Vehicle cannot be validated.');
          return false;
        }

        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<VehicleListDto>
            data={data?.data ?? []}
            columns={
              [
                ...actions,
                ...columnBuilder(OverviewColumns.VehicleList)
              ] as ColumnDef<VehicleListDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection={false}
            onSelectedRowsChange={setSelectedRows}
            enableRowSelection={(row) => !!row.original.isEnabled}
            meta={{
              getRowStyles: (row) => ({
                backgroundColor: row.original.isEnabled ? '' : '#DFDFDF'
              })
            }}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            getTooltip={(row) => row.original.validationMessage}
          />
        </QueryError>
      </div>
    );
  }
);

export default VehicleList;
