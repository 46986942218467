import { ActionMenu } from 'components/ActionMenu/ActionMenu';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useState } from 'react';
import OBUActions from './components/Telepass/OBU/OBUKmasterRegistrationDeregistration/OBUAction';
import OBUTollServiceModification from './components/Telepass/OBU/OBUTollServiceModification/OBUTollServiceModification';
import OrderAndPersonalizeObuActions from './components/T4E/OBU/T4EObuOrderActions/OrderAndPersonalizeObuActions';
import OrderAndNoPersonalizeObuActions from './components/T4E/OBU/T4EObuOrderActions/OrderAndNoPersonalizeObuActions';
import PersonalizeObuActions from './components/T4E/OBU/T4EPersonalizeObuActions/PersonalizeObuActions';
import DepersonalizeObuActions from './components/T4E/OBU/T4EDepersonalizeObuActions/DepersonalizeObuActions';
import DepersonalizeEtmOnlyObuActions from './components/T4E/OBU/T4EDepersonalizeObuActions/DepersonalizeEtmOnlyObuActions';
import { Customer } from '../Customer/types';
import ObuReplacement from './components/Telepass/OBU/OBUReplacement/ObuReplacement';
import CompleteObuTransfer from './components/T4E/OBU/CompleteObuTransfer/CompleteObuTransfer';
import T4EObuTransfer from './components/T4E/OBU/T4EObuTransfer/T4EObuTransfer';
import TerminateObu from './components/T4E/OBU/T4ETerminateObu/TerminateObu';
import T4ECustomerRegistration from './components/T4E/Customer/T4ECustomerRegistration/T4ECustomerRegistration';
import T4ECompleteCustomerRegistration from './components/T4E/Customer/T4ECustomerRegistration/T4ECompleteCustomerRegistration';
import T4EUpdateCustomer from './components/T4E/Customer/T4ECustomerRegistration/T4EUpdateCustomer';
import T4ERequestHistory from './components/T4E/Customer/T4ERequestHistory/T4ERequestHistory';
import T4EValidation from './components/T4E/utils/T4EValidation';

interface ActionsProps {
  customer: Customer;
}

const Actions: React.FC<ActionsProps> = ({ customer }) => {
  const [selectedMenu, setSelectedMenu] = useState<string | null>();

  const customerId = customer?.customerID as string;
  const customerName = customer?.name as string;

  const handleMenuClick = (menu: MenuInfo) => {
    const { key } = menu;

    setSelectedMenu(key);
  };

  const handleOnCloseModal = () => {
    setSelectedMenu('T4E');
  };

  return (
    <div>
      <ActionMenu onMenuClick={handleMenuClick} />

      {selectedMenu === 'obuKmasterRegistrationDeregistration' && (
        <OBUActions customerId={customerId} setSelectedMenu={setSelectedMenu} />
      )}

      {selectedMenu === 'orderAndPersonalizeObu' && (
        <T4EValidation
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        >
          <OrderAndPersonalizeObuActions
            CustomerId={customerId}
            customerName={customerName}
            handleOnCloseModal={handleOnCloseModal}
          />
        </T4EValidation>
      )}

      {selectedMenu === 'orderObu' && (
        <T4EValidation
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        >
          <OrderAndNoPersonalizeObuActions
            CustomerId={customerId}
            customerName={customerName}
            handleOnCloseModal={handleOnCloseModal}
          />
        </T4EValidation>
      )}

      {selectedMenu === 'transferObu' && (
        <T4EValidation
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        >
          <T4EObuTransfer
            CustomerId={customerId}
            customerName={customerName}
            customerNumber={customer.customerNo as string}
            handleOnCloseModal={handleOnCloseModal}
          />
        </T4EValidation>
      )}

      {selectedMenu === 'personalizeObu' && (
        <PersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'depersonalizeObu' && (
        <DepersonalizeObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'depersonalizeObuEtmOnly' && (
        <DepersonalizeEtmOnlyObuActions
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'obuTollServiceModification' && (
        <OBUTollServiceModification
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'obuReplacement' && (
        <ObuReplacement
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
          customerName={customer.name as string}
        />
      )}

      {selectedMenu === 'completeTransferObu' && (
        <CompleteObuTransfer
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 'terminateObu' && (
        <TerminateObu
          CustomerId={customerId}
          customerName={customerName}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}

      {selectedMenu === 't4eRegisterCustomer' && (
        <T4ECustomerRegistration
          handleOnCloseModal={handleOnCloseModal}
          CustomerId={customerId}
          customerName={customerName}
        />
      )}

      {selectedMenu === 't4eCompleteCustomerRegistration' && (
        <T4ECompleteCustomerRegistration
          handleOnCloseModal={handleOnCloseModal}
          CustomerId={customerId}
          customerName={customerName}
        />
      )}

      {selectedMenu === 't4eUpdateCustomer' && (
        <T4EUpdateCustomer
          handleOnCloseModal={handleOnCloseModal}
          CustomerId={customerId}
          customerName={customerName}
        />
      )}

      {selectedMenu === 't4eRequestHistory' && (
        <T4ERequestHistory
          CustomerId={customerId}
          handleOnCloseModal={handleOnCloseModal}
        />
      )}
    </div>
  );
};

export default Actions;
