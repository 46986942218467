const TOLL_FRANCE_ID = '7c487123-0744-426c-922d-dec4cedfee5c';
export const VehcleProductIDsForHideFinish = {
  Hungary: 'e14db320-b49f-4105-9934-03d1db4c7277',
  Bulgaria: '1aa077b9-3cc3-4af9-95fe-b82d97cd1b5f',
  'Toll France': TOLL_FRANCE_ID,
  Poland: 'e0c406e9-ce5b-4548-9623-23d4e837e992',
  polandNew: 'a9b5ced4-7de1-4a31-b3cf-b78baaa69393' // remove post poland migration
};

interface EnumValues {
  key: string | number;
  name: string;
  shortName?: string;
  value?: number;
  required?: boolean;
}

export interface Enums {
  [key: string]: EnumValues;
}

export const TypeProductEnum: Enums = {
  Hungary: {
    key: 'e14db320-b49f-4105-9934-03d1db4c7277',
    name: 'Hungary',
    shortName: 'HUgo'
  },
  Bulgaria: {
    key: '1aa077b9-3cc3-4af9-95fe-b82d97cd1b5f',
    name: 'Bulgaria',
    shortName: 'BGria'
  },
  'Toll France': {
    key: TOLL_FRANCE_ID,
    name: 'Toll France'
  },
  Poland: {
    key: 'e0c406e9-ce5b-4548-9623-23d4e837e992',
    name: 'Poland',
    shortName: 'PLT'
  },
  polandNew: {
    key: 'a9b5ced4-7de1-4a31-b3cf-b78baaa69393',
    name: 'Poland New'
  },
  Switzerland: {
    key: 'f2539515-de23-443d-bd19-a942e4f5fb12',
    name: 'Switzerland',
    shortName: 'CHlsv'
  }, // remove post poland migration
  Austrian_Toll: {
    key: '07a89a5e-1730-4a7d-9f95-912671b4e47a',
    name: 'Austrian Toll',
    shortName: 'AUT'
  },
  Toll_Germany: {
    key: 'e03ba932-b44d-408d-8860-fb92a7171ddb',
    name: 'Toll Germany',
    shortName: 'GER'
  },
  Belgium: {
    key: '2fac54ab-0c49-4bae-9bfd-932f5a083858',
    name: 'Belgium',
    shortName: 'BEL'
  },
  Toll_France: {
    key: TOLL_FRANCE_ID,
    name: 'Toll France',
    shortName: 'FRtis'
  }
};

export const TrailerTypeEnum: Enums = {
  No_trailer: {
    key: '52670d54-ecd1-4db7-8424-0313cff23d7e',
    name: 'No trailer'
  },
  Trailer: {
    key: '6a78ee4f-b706-442d-b6f5-1ec324567faa',
    name: 'Trailer'
  },
  Semi_trailer: {
    key: 'b8164d2b-75b9-4113-b7c3-44c1d6be953d',
    name: 'Semi-trailer'
  }
};

const CountryTypeEnum: Enums = {
  Germany: {
    key: 1,
    name: 'Germany'
  },
  Belgium: {
    key: 2,
    name: 'Belgium'
  },
  Switzerland: {
    key: 100120823,
    name: 'Switzerland'
  },
  Liechtenstein: {
    key: 100120811,
    name: 'Liechtenstein'
  }
};

export const VehicleCategoryTypeEnum: Enums = {
  M1: {
    key: '000fa969-989e-4cbb-92e6-014d6b49ada9',
    name: 'M1'
  },
  N3: {
    key: 'af4b3f75-bb8e-4416-8fb8-44e3d69966a8',
    name: 'N3'
  },
  N2: {
    key: 'd222056b-4834-4c6d-b9fc-503810eff4bd',
    name: 'N2'
  },
  M2: {
    key: 'c4d63872-27a1-47d4-b04f-a049457688a4',
    name: 'M2'
  },
  M3: {
    key: '00c7d154-9305-4d79-ad0c-b8bda9acd734',
    name: 'M3'
  },
  N1: {
    key: '9cfd89af-9367-4bae-b8ab-e2d5e380b128',
    name: 'N1'
  }
};

export const TypeVehicleDocumentEnum: Enums = {
  Vehicle_Declaration_document: {
    key: '8f3fd6fe-ff55-4339-9557-27b0bb07629c',
    name: 'Vehicle Declaration document',
    value: 84
  },
  Vehicle_Registration_document: {
    key: 'c3a0fa3b-c4d9-4c59-83a0-44c8c87decd6',
    name: 'Vehicle Registration document',
    value: 0
  },
  Vehicle_France_Barcode_document: {
    key: '04f982e0-311f-4789-bff9-d9800e37551a',
    name: 'Vehicle France Barcode document',
    value: 85
  },
  Vehicle_Euro_Certificate_document: {
    key: 'a2dfb01e-a948-4c13-9449-e3ec5223cfa0',
    name: 'Vehicle Euro Certificate document',
    value: 0
  },
  Vehicle_Scandinavia_Declaration_document: {
    key: 'D14178FD-AF7A-429B-B0C6-B4DAD27E3A1C',
    name: 'Vehicle Scandinavia Declaration document',
    value: 84
  },
  Vehicle_Certificate_of_Conformity: {
    key: '4833f099-e998-4d32-807e-e129b71b43d7',
    name: 'Vehicle Certificate of Conformity',
    value: 0
  },
  Vehicle_Cost_Insurence_and_Freight: {
    key: 'b18811a4-66f0-4856-bfef-e321a1c0ffb9',
    name: 'Vehicle Cost, Insurence, and Freight',
    value: 0
  }
};
